import React from 'react'
import LaunchIcon from '@mui/icons-material/Launch'
import { connect } from 'react-redux'
import { ChildDataProps, graphql, QueryControls } from '@apollo/react-hoc'
import { gql } from '@apollo/client'
import { compose } from 'redux'
import style from './style.css'
import { flattenMessages, formateMessage } from 'containers/Insight/utils'

interface IProps {
  isCsi: boolean|undefined
  languageSelected: string
}

const Footer: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
  <footer className={`flex justify-center pb-4 pt-8 absolute bottom-0 left-0 w-full ${style.footerHeight}`}>
    <section className="text-center">
      {props?.isCsi === false && (
      <div>
        {formateMessage('Net Promoter, Net Promoter Score, and NPS are trademarks of Satmetrix Systems, Inc., Bain & Company, Inc., and Fred Reichheld.', 'Net Promoter, Net Promoter Score, and NPS are trademarks of Satmetrix Systems, Inc., Bain & Company, Inc., and Fred Reichheld.')}
      </div>
      )}
      <div>
        <a href="https://www.zykrr.com" target="_blank" rel="noopener noreferrer">
          {formateMessage('Copyright © 2024 Zykrr Technologies Pvt Ltd |', 'Copyright © 2024 Zykrr Technologies Pvt Ltd |')}
          {' '}
          {formateMessage('App version', 'App version')}:
          {' '}
          {process.env.SOURCE_COMMIT}
          <LaunchIcon fontSize="small" />
        </a>
      </div>
    </section>
  </footer>
)
}

const mapStateToProps = (state) => ({
  isCsi: state.currentCampaign?.campaignDetails?.campaignType?.includes('CSI'),
  languageSelected: state.currentCampaign?.currentSelectedLanguage || 'English',
  getAllGlobalTranslation: state.currentCampaign?.getAllGlobalTranslation,
})

const enhance = compose(
  connect(mapStateToProps),
)

export default enhance(Footer)
