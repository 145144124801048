import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'

interface IProps {
  message: string
}

const HelpTip: React.FunctionComponent<IProps> = (props) => (
  <>
    {' '}
    <Tooltip title={props.message} aria-label={props.message} arrow>
      <InfoIcon fontSize="small" />
    </Tooltip>
  </>
)

export default HelpTip
