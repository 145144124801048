//  Contains functions and values that will be common acrosss different tags

import { endOfWeek, format, startOfWeek } from 'date-fns'
import { IMetricConfig } from 'graphql/graphql.d'
import { generatePath } from 'react-router'
import { cloneDeep } from 'lodash'
import { store } from 'configureStore'
import { gql } from '@apollo/client'
import client from 'graphql/client'
import React from 'react';
import ReactDOM from 'react-dom';
import HelpTip from 'components/HelpTip'
import pages from '../App/pages'

export enum ComponentKind {
  HOT_AREA = 'HOT_AREA',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  OPENEND = 'OPENEND',
  RATING = 'RATING',
  NEW_PAGE = 'NEW_PAGE',
  FIXED_TEXT = 'FIXED_TEXT',
  RANKING = 'RANKING',
  DATE = 'DATE',
  RATING_GRID = 'RATING_GRID',
  MCQ_DRILLDOWN = 'MCQ_DRILLDOWN',
}
export interface IQuestionnaireComponent {
  questionId: string
  id: string
  componentKind: ComponentKind
  config: any
  next: string | undefined
  previous: string | undefined
  questionOptions: any
  questionType: string
  questionStatement: any
  nextQuestionId: string
  previousQuestionId: string
  questionShortCode: string
  questionAttributeName: string
  questionWhenToAsk: string
  questionRatingPoints: any
  drilldownQuestions: string
  questionConditions: any
  isNPS: boolean
  isCSAT: boolean
  isDimension: boolean
  isMultiLine: boolean
  isDemographicsQuestion: boolean
  isPrimaryDimension: boolean
  isHiddenInResponseDetails: boolean
  inTopThreeDimension: boolean
}

export const bajajCampaignIds = ['7fbc0b70-8ea1-4d69-826c-4c7e67f419b6', '1d0eb4ca-cacd-4cf2-99a1-2b2f81ca0666', 'ecd29385-07b7-421a-850d-4ddb8b3c1f12', '29836fc1-7e96-4c70-9e45-f5823059524b', '6b847369-a351-427c-86a7-13c3d2628785', '00c1b42a-d801-47b2-96c9-3c415f2b1be3', 'c485741e-9169-44c6-be3a-43eeb3fa3c3a', '8ec43a77-00fc-4724-9efa-aec90dbdc2d9']
export const bajajServiceCampaignIds = ['7fbc0b70-8ea1-4d69-826c-4c7e67f419b6', 'ecd29385-07b7-421a-850d-4ddb8b3c1f12', '6b847369-a351-427c-86a7-13c3d2628785', '8ec43a77-00fc-4724-9efa-aec90dbdc2d9']
export const bajajShowroomCampaignIds = ['1d0eb4ca-cacd-4cf2-99a1-2b2f81ca0666', '29836fc1-7e96-4c70-9e45-f5823059524b', '00c1b42a-d801-47b2-96c9-3c415f2b1be3', 'c485741e-9169-44c6-be3a-43eeb3fa3c3a']
export const latamCampaignIds = ['c623ae81-b68b-4769-b806-8e65fc472683', '4f05fdcc-1970-4b7c-80fa-4aa334124bc5', 'a9dd5385-52c3-492f-b740-e7f62a7b1a93', '8e29b197-e0cf-4cce-b694-9da93f2a8b99', '7b187a90-901b-41b0-b563-92c09f3b5d67', '552d385b-0026-4719-8933-6a71a9644d97', 'ad40f485-53cf-4ccd-9386-89e723c78815', 'd8ccb5cb-458f-42a0-adce-34e8d21b6771', '85b58426-efc6-43a4-b4b4-7e159ca68507',
  '63ac0ae3-d5a7-4362-9829-81f8e4aec1b8']

let currentLanguageSelectedObj: null|Object = null

export const formateMessage = (currentIdTemp, defaultMessage) => {
  const currentId = typeof currentIdTemp === 'string' ? currentIdTemp?.trim() : currentIdTemp
  try {
    const storeForStore = store
    const dataForTranslations = storeForStore.getState()

    if (!dataForTranslations.currentCampaign.getAllGlobalTranslation) return defaultMessage

    currentLanguageSelectedObj = dataForTranslations.currentCampaign.getAllGlobalTranslation
    return currentLanguageSelectedObj ? currentLanguageSelectedObj?.[currentId] || defaultMessage : defaultMessage
  } catch (e) {
    console.log('error in last fetch data', e)
    return currentLanguageSelectedObj?.[currentId] || defaultMessage
  }
}

export const getSingleLabelByValue = (questionId: string, value: string, questionnaire: Array<any>) => {
  if (!value) {
    return '-'
  }
  if (value === '') {
    return '-'
  }
  const optionsFind = questionnaire.find((x) => x.id === questionId)
  if (optionsFind === undefined) {
    return value
  }
  const allOptions = optionsFind.config.options
  const selectedOption: { label: string, value: string, shortCode: string } = allOptions.find((x: { value: string }) => x.value === value)
  if (!selectedOption) {
    return value
  }
  return selectedOption.shortCode || selectedOption.label
}

export const getAnswerLabelByValue = (questionId: string, value: Array<string>, questionnaire: Array<any>) => {
  try {
    if (questionnaire && questionnaire.length && questionnaire[0].questionId) {
      if (!value) {
        return ['-']
      }
      if (value.length === 0) {
        return ['-']
      }
      if (typeof (value) === 'string' || questionnaire.length === 0) { // it should not be string but for some reason if it got stored as string, return as it is
        console.warn('Answer of MCQ questionId %s should have been an array but received a string: %s. Returning as it is', questionId, value)
        return [value.toString()]
      }
      const allOptions = questionnaire.find((x) => x.questionId === questionId).questionOptions
      const allValues = value.map((val) => {
        const selectedOption: { label: string, value: string, shortCode: string } = allOptions.find((x: { value: string }) => x.value === val)
        if (!selectedOption) {
          // this option has been removed and does not exist any more in questionnaire, return as is
          return val
        }
        return selectedOption.shortCode || selectedOption.label
      })
      return allValues
    }
    if (!value) {
      return ['-']
    }
    if (value.length === 0) {
      return ['-']
    }
    if (typeof (value) === 'string' || questionnaire.length === 0) { // it should not be string but for some reason if it got stored as string, return as it is
      console.warn('Answer of MCQ questionId %s should have been an array but received a string: %s. Returning as it is', questionId, value)
      return [value.toString()]
    }
    const allOptions = questionnaire.find((x) => x.id === questionId).config.options
    const allValues = value.map((val) => {
      const selectedOption: { label: string, value: string, shortCode: string } = allOptions.find((x: { value: string }) => x.value === val)
      if (!selectedOption) {
        // this option has been removed and does not exist any more in questionnaire, return as is
        return val
      }
      return selectedOption.shortCode || selectedOption.label
    })
    return allValues
  } catch (e) {
    console.log('Errror', e)
    console.log(questionId, value, questionnaire)
    return [value.toString()]
  }
}

export const getOptionsToShortCodeMap = (questionnaire: Array<any>, questionId: string | Array<string>) => {
  const map: { [key: string]: string } = {}
  if (Array.isArray(questionId)) {
    questionId.forEach((quId) => {
      const mcqQuestions = questionnaire?.find((x) => x.questionId === quId)
      if (mcqQuestions) {
        const { questionOptions: options } = mcqQuestions
        options.forEach((x: { value: string | number; shortCode: any; label: any }) => {
          map[x.value] = x.shortCode || x.label || x.value
        })
      }
    })
  } else {
    const mcqQuestions = questionnaire?.find((x) => x.questionId === questionId)
    if (mcqQuestions) {
      const { questionOptions: options } = mcqQuestions
      options.forEach((x: { value: string | number; shortCode: any; label: any }) => {
        map[x.value] = x.shortCode || x.label || x.value
      })
    }
  }

  return map
}

export const chartLoadingMessage = 'Crunching numbers for you! Please give us a moment'

export const getColorByMetricConfig = (metricConfig: IMetricConfig[], campaignId: string, score: number, type: 'NPS' | 'CSAT') => {
  const configByType = metricConfig.find((x) => x.type === type && x.campaignId === campaignId) as IMetricConfig
  if (score <= (configByType ? configByType.detractorUpperRange : 6)) {
    return '#cc0000'
  } if (score > (configByType ? configByType.promoterLowerRange : 8)) {
    return '#0e9100'
  }
  return '#FFA500'
}

export const getCampaignQuestionnaire = (completeQuestionnaire: any[], answers: Record<string, unknown>) => {
  const answerKeys = Object.keys(answers)
  let anyQuestionNode: any
  for (let i = 0; i < answerKeys.length; i += 1) {
    anyQuestionNode = completeQuestionnaire.find((q: { questionId: string }) => q.questionId === answerKeys[i])
    if (anyQuestionNode) break
    console.warn(answerKeys[i], 'is no longer part of questionnaire but present in answers')
  }

  // if (!anyQuestionNode) throw new Error('None of the answers match against any questions in the questionnaire')

  // start from the given question and go till the there is .previous. It will reach to the start of questionnaire
  let currentNode = completeQuestionnaire.find((o: { questionId: any }) => o?.questionId === anyQuestionNode?.questionId)
  while (currentNode?.previousQuestionId) {
    // eslint-disable-next-line no-loop-func
    const previousNode = completeQuestionnaire.find((o: { questionId: any }) => o.questionId === currentNode.previousQuestionId)
    currentNode = previousNode
  }

  // todo: optimize. currentNodeId moves back and then goes forth. the questionnaire
  // can be extract in one pass instead of two pass in worst case

  const requiredQuestionnaire: IQuestionnaireComponent[] = []

  // currentNodeId is at start of questionnaire. go till the end and extract the questionnaire
  while (currentNode) {
    requiredQuestionnaire.push(currentNode)
    // eslint-disable-next-line no-loop-func
    const nextNode = completeQuestionnaire.find((o: { questionId: any }) => o.questionId === currentNode.nextQuestionId)
    currentNode = nextNode
  }

  return requiredQuestionnaire
}

export const formatString = (inputString: string) => {
  let inputStringTemp = inputString
  if (typeof inputStringTemp !== 'string') {
    inputStringTemp = inputString.toString()
  }
  return inputStringTemp.includes('\\') ? inputStringTemp.split('\\').join('\\\\') : inputStringTemp
}

export const getGMT = (minutes: number) => {
  const minute = minutes < 0 ? minutes * -1 : minutes
  const m = minute % 60
  const h = (minute - m) / 60
  return `${h.toString()}:${m < 10 ? '0' : ''}${m.toString()}`
}

export const roundUptoDecimalPlace = (npsScore: number, roundOffPoint: number) => {
  if (Number(npsScore.toFixed(roundOffPoint)) % 1 === 0) {
    return Math.round(npsScore)
  }
  return Number(npsScore.toFixed(roundOffPoint))
}

const maxBarWidth = 28

export const satisfactionScoreDistributionChartOptions = (
  csatDataPerKeywordGroup: {
    keyword: string;
    satisfied: number;
    unsatisfied: number;
    highlySatisfied: number;
    netHighlySatisfield: number;
  }[],
  csatMetricConfig: IMetricConfig,
  title?: any,
  helpText?: string
): Highcharts.Options => {
  const helpTextId = `helptext-tooltip-${Math.random().toString(36).substr(2, 9)}`;

  const baseTitle = title ? formateMessage(title, title) : `${formateMessage(csatMetricConfig.metricLabelShort || 'CSAT', csatMetricConfig.metricLabelShort || 'CSAT')} ${formateMessage('Distribution', 'Distribution')}`;
  const titleConfig = helpText ? {
    useHTML: true,
    text: `<div class="chart-title-container" style="display: flex; align-items: center; gap: 4px;">
            ${baseTitle}
            <div id="${helpTextId}"></div>
           </div>`,
  } : {
    text: baseTitle
  };

  const chartEvents = helpText ? {
    events: {
      render: function() {
        const tooltipContainer = document.getElementById(helpTextId);
        if (tooltipContainer && !tooltipContainer.hasChildNodes()) {
          ReactDOM.render(
            React.createElement(HelpTip, { message: helpText }),
            tooltipContainer
          );
        }
      },
      destroy: function() {
        const tooltipContainer = document.getElementById(helpTextId);
        if (tooltipContainer) {
          ReactDOM.unmountComponentAtNode(tooltipContainer);
        }
      }
    }
  } : {};

  const series: any = [
    {
      type: 'bar',
      name: formateMessage(csatMetricConfig ? csatMetricConfig.detractorLabel : 'Detractors', csatMetricConfig ? csatMetricConfig.detractorLabel : 'Detractors'),
      color: '#cc0000',
      data: csatDataPerKeywordGroup.map((v) => v.unsatisfied),
    },
    {
      type: 'bar',
      name: formateMessage(csatMetricConfig ? csatMetricConfig.passiveLabel : 'Passives', csatMetricConfig ? csatMetricConfig.passiveLabel : 'Passives'),
      color: '#FFA500',
      data: csatDataPerKeywordGroup.map((v) => v.satisfied),
    },
    {
      type: 'bar',
      name: formateMessage(csatMetricConfig ? csatMetricConfig.promoterLabel : 'Promoters', csatMetricConfig ? csatMetricConfig.promoterLabel : 'Promoters'),
      color: '#0e9100',
      data: csatDataPerKeywordGroup.map((v) => v.highlySatisfied),
    },
  ]
  if (csatMetricConfig?.promoterLowerRange === csatMetricConfig?.detractorUpperRange) {
    series.splice(series.findIndex((val) => val.name === formateMessage(csatMetricConfig ? csatMetricConfig.passiveLabel : 'Passives', csatMetricConfig ? csatMetricConfig.passiveLabel : 'Passives')), 1)
  }

  return ({
    chart: {
      type: 'bar',
      height: 100 + (maxBarWidth * csatDataPerKeywordGroup.length),
      ...chartEvents // Added chart events
    },
    title: {
      ...titleConfig, // Modified title config
      align: 'left',
      x: 0,
      margin: 15,
      style: {
        fontSize: '1rem',
        fontWeight: '500',
      },
    },
    xAxis: {
      categories: csatDataPerKeywordGroup.map((v) => formateMessage(v.keyword, v.keyword)),
    },
    yAxis: {
      min: 0,
      visible: false,
      title: {
        text: formateMessage('Percentage of responses', 'Percentage of responses'),
      },
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    series,
    tooltip: {
      formatter() {
        const points = this.points as Highcharts.TooltipFormatterContextObject[]
        points.reverse()
        const toReturn = points.reduce((s, point) => `${s} <span style="color:${(point.series as unknown as any).color}">${point.series.name}</span>: <b>${point.y}</b> (${point.percentage?.toFixed(1) || '-'}%)<br/>`, '')
        return `${toReturn}`
      },
      shared: true,
    },
    plotOptions: {
      bar: {
        pointPadding: 0,
        groupPadding: 0,
      },
      series: {
        // @ts-ignore
        // pointWidth: 20,
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          formatter() {
            if (this.percentage) {
              return `${this.percentage.toFixed(1)}%`
            }
            return ''
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  })
}

export const tabularChart = ({
  title,
  data,
  min,
  max,
  helpText,
}: {
  title: string
  data: Array<number | null>
  min: number,
  max: number
  helpText?: string
}): Highcharts.Options => {
  let loweBound = -100
  let upperBound = 100
  const greater = Math.max(Math.abs(min), Math.abs(max))
  loweBound = Math.max(-100, (greater + 20) * (-1))
  upperBound = Math.min(100, greater + 20)

  // Prepare the title configuration based on whether helpText is provided
  const titleConfig = helpText ? {
    useHTML: true,
    text: `<div style="display: flex; align-items: center; justify-content: center; gap: 4px;">
            ${title}
            <span id="helptext-tooltip"></span>
          </div>`,
  } : {
    text: title,
  };

  // Prepare chart events only if helpText is provided
  const chartEvents = helpText ? {
    events: {
      render: function() {
        const tooltipContainer = document.getElementById('helptext-tooltip');
        if (tooltipContainer && !tooltipContainer.hasChildNodes()) {
          const HelpTipElement = React.createElement(HelpTip, { message: helpText });
          ReactDOM.render(HelpTipElement, tooltipContainer);
        }
      }
    }
  } : {};

  return ({
    chart: {
      type: 'bar',
      marginBottom: 52,
      ...chartEvents
    },
    title: {
      ...titleConfig,
      align: 'center',
      style: {
        fontSize: '1rem',
        fontWeight: '500',
        whiteSpace: 'nowrap',
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      visible: false,
    },
    yAxis: {
      min: loweBound - 4, // add extra padding so that data labels don't come over the bars
      max: upperBound + 4, // add extra padding so that data labels don't come over the bars
      visible: true,
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointPadding: 0,
        groupPadding: 0,
        pointWidth: maxBarWidth,
        maxPointWidth: maxBarWidth,
        dataLabels: {
          enabled: true,
          style: {
            color: '#666666',
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [{
      data,
      name: '',
      type: 'bar',
    }],
    navigation: {
      buttonOptions: {
        y: 0,
      },
    },
    exporting: {
      enabled: false,
    },
  })
}

export const singleValueChart = ({
  title,
  data,
  clickableTitle = false,
  helpText,
}: {
  title: string
  data: Array<number | null>
  clickableTitle?: boolean
  helpText?: string
}): Highcharts.Options => {
  const titleStyle: Highcharts.CSSObject = {
    fontSize: '1rem',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  }
  if (clickableTitle) {
    titleStyle.cursor = 'pointer'
  }

  const tooltipId = `helptext-tooltip-${Math.random().toString(36).substr(2, 9)}`;

  // Split title into main text and sort indicator if present
  const titleParts = title.split(/(&[ud]arr;)/)
  const mainTitle = titleParts[0]
  const sortIndicator = titleParts[1] || ''

  const titleConfig: Highcharts.TitleOptions = helpText ? {
    useHTML: true,
    text: `<div class="chart-title-container" style="display: flex; align-items: center; gap: 4px;">
            <span class="clickable-title" style="flex-shrink: 1;">${mainTitle}${sortIndicator}</span>
            <span id="${tooltipId}" class="help-tooltip" style="flex-shrink: 0; display: inline-block;"></span>
          </div>`,
    align: 'left' as Highcharts.AlignValue,
    style: titleStyle,
  } : {
    text: title,
    align: 'left' as Highcharts.AlignValue,
    style: titleStyle,
  };

  const chartEvents = helpText ? {
    events: {
      render: function(this: Highcharts.Chart) {
        const tooltipContainer = document.getElementById(tooltipId);
        if (tooltipContainer && !tooltipContainer.hasChildNodes()) {
          const HelpTipElement = React.createElement(HelpTip, { message: helpText });
          ReactDOM.render(HelpTipElement, tooltipContainer);
        }

        // Add click handler to the title if clickable
        if (clickableTitle) {
          const titleElement = this.container.querySelector('.clickable-title') as HTMLElement;
          if (titleElement) {
            titleElement.style.cursor = 'pointer';
            // Remove existing click handler to prevent duplicates
            titleElement.removeEventListener('click', titleClickHandler);
            titleElement.addEventListener('click', titleClickHandler);
          }
        }
      }
    }
  } : {};

  return {
    chart: {
      type: 'bar',
      ...chartEvents
    },
    title: titleConfig,
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      visible: false,
    },
    yAxis: {
      visible: false,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    plotOptions: {
      bar: {
        pointWidth: maxBarWidth,
        maxPointWidth: maxBarWidth,
      },
      series: {
        stacking: 'normal',
        dataLabels: {
          align: 'left',
          enabled: true,
          color: '#666666',
        },
        color: 'transparent',
      } as Highcharts.PlotSeriesOptions,
    },
    tooltip: {
      enabled: false,
    },
    series: [{
      type: 'bar',
      data,
      name: '',
      pointWidth: 20,
    }],
    exporting: {
      enabled: false,
    },
  }
}

// Helper function to handle title clicks
const titleClickHandler = (event: Event) => {
  const titleElement = event.currentTarget as HTMLElement;
  const text = titleElement.textContent || '';
  const currentArrow = text.includes('↑') ? '↑' : '↓';
  const newArrow = currentArrow === '↑' ? '↓' : '↑';

  // Dispatch a custom event that your React component can listen to
  const customEvent = new CustomEvent('titleSort', {
    detail: { sortDirection: newArrow === '↑' ? 'asc' : 'desc' }
  });
  document.dispatchEvent(customEvent);
}

export const dateFormatterForWeeklyChart = (date: Date, index: number | undefined, timeRangeStartDate: Date, timeRangeEndDate: Date): string => {
  try {
    let startDate = startOfWeek(date, { weekStartsOn: 1 })
    let endDate = endOfWeek(date, { weekStartsOn: 1 })

    if (startDate.getTime() < timeRangeStartDate.getTime()) {
      startDate = cloneDeep(timeRangeStartDate)
    }

    if (endDate.getTime() > timeRangeEndDate.getTime()) {
      endDate = cloneDeep(timeRangeEndDate)
    }

    // added because if the reponse date and end date gets same
    if (startDate.getDate() === endDate.getDate()) {
      if (index !== 0) {
        endDate.setDate(startDate.getDate() + 6)
      }
    }

    let includeYear = true
    let includeStartingMonth = true
    if (startDate.getFullYear() === endDate.getFullYear()) {
      includeYear = false
    }
    if (startDate.getMonth() === endDate.getMonth()) {
      includeStartingMonth = false
    }
    let label1 = ''
    if (includeStartingMonth) {
      label1 = format(startDate, 'd MMM')
    } else {
      label1 = format(startDate, 'd')
    }
    const label2 = format(endDate, 'd MMM')
    let label = (index === 0 && startDate.getDate() === endDate.getDate()) ? `${format(startDate, 'd MMM')}` : `${label1} - ${label2}`

    if (includeYear) {
      label += ` ${endDate.getFullYear()}`
    }
    if (index === 0 && includeYear === false) {
      label += ` ${endDate.getFullYear()}`
    }
    return label
  } catch {
    return ''
  }
}

export const getMonthForMonthlyChart = (integer: number) => {
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthArray[integer - 1]
}

export const getPathForRightClick = (tabName: string, campaignId: string): string => {
  switch (tabName) {
    case 'Overview': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/overview`
    }
    case 'Segment scores': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/satisfaction-score`
    }
    case 'Segment scores Pre': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/satisfaction-score-pre`
    }
    case 'Drilldown': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/drilldown`
    }
    case 'Segment-drilldown': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/segment-drilldown`
    }
    case 'KPI satisfaction score': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/kpi-satisfaction-score`
    }
    case 'KPI satisfaction Pre': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/kpi-satisfaction-pre`
    }
    case 'Satisfaction grid': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/satisfaction-grid`
    }
    case 'Responses': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/responses`
    }
    case 'Engagement Summary': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/engagement-summary`
    }
    case 'Engagement Summary Iframe': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/engagement-summary`
    }
    case 'User Analytics': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/user-analytics`
    }
    case 'Escalations': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/escalations`
    }
    case 'Huddle Cases': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/huddle-cases`
    }
    case 'Huddle Scores': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/huddle-scores`
    }
    case 'Text Analysis': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/text-analysis`
    }
    case 'Regression Analytics': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/regression-analytics`
    }
    case 'Innerloop Resolution': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/innerloop-resolution`
    }
    case 'Huddle Calendar': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/huddle-calendar`
    }
    case 'Huddle': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/huddle`
    }
    case 'Competition Analysis': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/competition-analysis`
    }
    case 'Participants': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/participants`
    }
    case 'Carousel View': {
      return `${generatePath(pages.analytics, {
        campaignId,
      })}/carousel-view`
    }
    default: {
      return ''
    }
  }
}

export function getFileSize(file?: File) {
  if (!file) return null
  const nBytes = file.size
  let sOutput = `${nBytes} bytes`
  // optional code for multiples approximation
  const aMultiples = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  for (let nMultiple = 0, nApprox = nBytes / 1024; nApprox > 1; nApprox /= 1024, nMultiple += 1) {
    sOutput = `${nApprox.toFixed(3)} ${aMultiples[nMultiple]} (${nBytes} bytes)`
  }
  return sOutput
}

export function getKpiReportforTotal(data: any) {
  let npsTotalPormoters = 0
  let npsTotalPassives = 0
  let npsTotalDetractors = 0
  let otpRateTotalNumerator = 0
  let otpRateTotalDenominator = 0
  let responseRateTotalNumerator = 0
  let responseRateTotalDenominator = 0
  let callbackRateTotalNumerator = 0
  let callbackRateTotalDenominator = 0
  let huddleRateTotalNumerator = 0
  let huddleRateTotalDenominator = 0
  let loopclosureRateTotalNumerator = 0
  let loopclosureRateTotalDenominator = 0
  let resolutionRateTotalNumerator = 0
  let resolutionRateTotalDenominator = 0
  const overallTotal: string[] = []
  data.forEach((val) => {
    npsTotalPormoters += val.promoters ? Number(val.promoters) : 0
    npsTotalPassives += val.passives ? Number(val.passives) : 0
    npsTotalDetractors += val.detractors ? Number(val.detractors) : 0
    otpRateTotalNumerator += val.otpRateNumerator ? Number(val.otpRateNumerator) : 0
    otpRateTotalDenominator += val.otpDenominator ? Number(val.otpDenominator) : 0
    callbackRateTotalNumerator += val.callBackNumerator ? Number(val.callBackNumerator) : 0
    callbackRateTotalDenominator += val.callBackDenominator ? Number(val.callBackDenominator) : 0
    responseRateTotalNumerator += val.responseRateNumerator ? Number(val.responseRateNumerator) : 0
    responseRateTotalDenominator += val.responseRateDenominator ? Number(val.responseRateDenominator) : 0
    resolutionRateTotalNumerator += val.resolutionRateNumerator ? Number(val.resolutionRateNumerator) : 0
    resolutionRateTotalDenominator += val.resolutionRateDenominator ? Number(val.resolutionRateDenominator) : 0
    loopclosureRateTotalNumerator += val.loopClosureRateNumerator ? Number(val.loopClosureRateNumerator) : 0
    loopclosureRateTotalDenominator += val.loopClosureRateDenominator ? Number(val.loopClosureRateDenominator) : 0
    huddleRateTotalNumerator += val.huddleAchievementNumerator ? Number(val.huddleAchievementNumerator) : 0
    huddleRateTotalDenominator += val.huddleAchievementDenominator ? Number(val.huddleAchievementDenominator) : 0
  })
  const npsTotal = ((npsTotalPormoters - npsTotalDetractors) / (npsTotalPormoters + npsTotalPassives + npsTotalDetractors)) * 100
  const otpTotal = ((otpRateTotalNumerator / otpRateTotalDenominator) * 100)
  const responseTotal = ((responseRateTotalNumerator / responseRateTotalDenominator) * 100)
  const callbackTotal = ((callbackRateTotalNumerator / callbackRateTotalDenominator) * 100)
  const huddleTotal = ((huddleRateTotalNumerator / huddleRateTotalDenominator) * 100)
  const loopClosureTotal = ((loopclosureRateTotalNumerator / loopclosureRateTotalDenominator) * 100)
  const resolutionTotal = ((resolutionRateTotalNumerator / resolutionRateTotalDenominator) * 100)

  overallTotal.push(Number.isNaN(npsTotal) ? `${0.0}%` : `${Math.round(npsTotal)}%`)
  overallTotal.push(Number.isNaN(otpTotal) ? `${0.0}%` : `${Math.round(otpTotal)}%`)
  overallTotal.push(Number.isNaN(responseTotal) ? `${0.0}%` : `${Math.round(responseTotal)}%`)
  overallTotal.push(Number.isNaN(callbackTotal) ? `${100}%` : `${Math.round(callbackTotal)}%`)
  overallTotal.push(Number.isNaN(huddleTotal) ? `${100}%` : `${Math.round(huddleTotal)}%`)
  overallTotal.push(Number.isNaN(loopClosureTotal) ? `${100}%` : `${Math.round(loopClosureTotal)}%`)
  overallTotal.push(Number.isNaN(resolutionTotal) ? `${100}%` : `${Math.round(resolutionTotal)}%`)

  return overallTotal
}

export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate)

  const getFormattedAMPM = (date) => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const hours12 = hours % 12 || 12

    const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${ampm}`

    return formattedTime
  }

  const formattedDate = `${date.toLocaleDateString()} ${getFormattedAMPM(date)}`
  return formattedDate
}

export const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return re.test(email)
}

export const tabsName = (link: string): string => {
  const reporting = ['/overview', '/satisfaction-score', '/drilldown', '/segment-drilldown', '/kpi-satisfaction-score', '/satisfaction-grid', '/user-analytics', '/text-analysis', '/engagement-summary', '/responses', '/regression-analytics', '/kpi-satisfaction-pre', '/satisfaction-score-pre', '/competition-analysis', '/participants', '/carousel-view']
  const escalations = ['/huddle-scores', '/huddle-cases', '/innerloop-resolution', '/huddle-calendar', '/escalations', '/huddle']
  if (reporting.includes(link)) {
    return 'Reporting'
  }
  if (escalations.includes(link)) {
    return 'Escalation'
  }
  return ''
}

export const getColor = (name: string) => {
  if (name === 'Promoter') {
    return '#0e9100'
  } if (name === 'Passive') {
    return '#FFA500'
  } if (name === 'Detractor') {
    return '#cc0000'
  }
  return undefined
}

export const shouldDisplayHuddleCard = (campaignId: string): boolean => ['29836fc1-7e96-4c70-9e45-f5823059524b', '6b847369-a351-427c-86a7-13c3d2628785', '00c1b42a-d801-47b2-96c9-3c415f2b1be3', '7fbc0b70-8ea1-4d69-826c-4c7e67f419b6', 'ecd29385-07b7-421a-850d-4ddb8b3c1f12', '1d0eb4ca-cacd-4cf2-99a1-2b2f81ca0666', '2600aa81-70b4-4241-96cd-9f9f1d765b32', 'c485741e-9169-44c6-be3a-43eeb3fa3c3a', '8ec43a77-00fc-4724-9efa-aec90dbdc2d9', 'e4a52054-1f82-490f-a3c8-cb49572b96f2', 'fba73749-921d-408e-bc12-1de2eb821271', '6562af15-9e9d-4398-87e0-d8add365032b', '85db98a7-e6c7-4327-adb7-2bdf09d4a382', '71b7d320-0e02-44a0-82dc-b3c3e734ac80', '9bd8e527-49b6-403b-a72f-162c54c28c27', '63ac0ae3-d5a7-4362-9829-81f8e4aec1b8'].concat(latamCampaignIds).includes(campaignId)

export const rightToLanguagesCode = [
  'ar', // Arabic
  'arc', // Aramaic,
  'dv', // Divehi,
  'fa', // Persian,
  'ha', // Hausa,
  'he', // Hebrew,
  'khw', // Khowar,
  'ks', // Kashmiri,
  'ku', // Kurdish,
  'ps', // Pashto,
  'ur', // Urdu,
  'yi', // Yiddish
]

export const languageCodes = {
  English: 'en',
  Assamese: 'as',
  Hindi: 'hi',
  Bengali: 'bn',
  Marathi: 'mr',
  Gujarati: 'gu',
  Urdu: 'ur',
  Malayalam: 'ml',
  Kannada: 'kn',
  Kashmiri: 'ks',
  Sanskrit: 'sa',
  Sindhi: 'sd',
  Tamil: 'ta',
  Telugu: 'te',
  Punjabi: 'pa',
  'Odia/Oriya': 'or',
  Arabic: 'ar',
  Spanish: 'es',
  Portuguese: 'pt',
  German: 'de',
  French: 'fr',
  Chinese: 'zh',
  Indonesian: 'id',
  Turkish: 'tr',
}

export const flattenMessages = ((nestedMessages, language) => {
  if (nestedMessages === null) {
    return {}
  }
  const msg: { [key: string]: string } = {}
  Object.keys(nestedMessages).forEach((v) => {
    if (Object.keys(nestedMessages[v]).indexOf(language) > -1) {
      msg[v] = nestedMessages[v][language]
    }
  })

  return msg
})

export const updateDashBoardTranslation = async (campaignId: string | undefined, language: string | undefined): Promise<void> => {
  if (campaignId && language) {
    try {
      const languageTransactionResponse = await client.query({
        query: gql`
        query qy0101ju($campaignId: ID!, $language: String!){
          getLanguageTranslations(campaignId: $campaignId, language: $language)
        }
        `,
        variables: {
          campaignId,
          language,
        },
      })

      const languageTranslationData = languageTransactionResponse?.data?.getLanguageTranslations || null
      store.dispatch({
        type: 'GET_CAMPAIGN_TRANSLATION',
        payload: {
          getAllGlobalTranslation: languageTranslationData,
        },
      })
    } catch (e) {
      console.log('api failing while fetching data for language Translation', e)
    }
  }
}

export const capitalizeFirstLetter = (string) => string.toLowerCase()
  .split(' ')
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ')

export const isDarkColor = (color: string | null | undefined) => {
  if (!color) return false // If color is not provided, return false (indicating a light color, thus #000)

  color = color.replace('#', '')

  const r = parseInt(color.slice(0, 2), 16)
  const g = parseInt(color.slice(2, 4), 16)
  const b = parseInt(color.slice(4, 6), 16)

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return luminance < 0.6
}
